import * as React from 'react';
// import { StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import '../components/styles/prestations.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const Acces = () => {
  const { allContentfulPrestation: { edges } } = useStaticQuery(graphql`
      query PrestationQuery {
        allContentfulPrestation {
          edges {
            node {
              childContentfulPrestationDescriptionTextNode {
                childMarkdownRemark {
                  html
                }
              }
              title
              image {
                title
                file {
                  url
                }
              }
              rate
            }
          }
        }
      }
  `);
  return (
    <Layout>
      <Seo />
      <HeaderRandom />
      <div id='title'>
        <h1>Hypnothérapie</h1>
      </div>

      <div className='division'>
        <div className='container tarifs'>
          {edges.map(({ node: { title, rate, image, childContentfulPrestationDescriptionTextNode } }, key) =>
            <div className='col-md-4' key={key}>
              <div className='card'>
                <div className='intitule'><span>{title}</span></div>
                <div className='prix'><span>{rate}</span></div>
                <div className='text'>
                  <span dangerouslySetInnerHTML={{ __html: childContentfulPrestationDescriptionTextNode.childMarkdownRemark.html }} />
                </div>
                <div className='image t-center'><img src={`https:${image.file.url}`} height={200} placeholder='blurred' alt={image.title} /></div>
              </div>
            </div>)}
        </div>
      </div>
      <div className='division'>
        <div className='container'>
          <div className='col-md-12'>
            <p><b>Moyens de paiement acceptés : chèque ou espèces</b></p>
          </div>
        </div>
      </div>
    </Layout>
  )
  ;
};

export default Acces;
